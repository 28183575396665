(function (angular, app) {
    'use strict';

    var GET_SPECIALS_PARAMS = {
        from: 0,
        size: 1,
        filters: {
            must: {
                lessThan: {
                    startDate: new Date()
                },
                greaterThan: {
                    endDate: new Date()
                },
                term: {
                    displayOnWeb: true
                }
            }
        }
    };

    app.controller('HeaderCtrl', [
        '$scope', '$rootScope', '$state', '$timeout', '$document', '$q', '$element', 'Api', 'Util', 'Config', 'User', 'Dialog',
        'Bubble', 'Toast', 'LocalStorage', 'HubService', 'ORGANIZATION_TYPES', 'NEWSLETTER_POPUP_BEHAVIOR', 'DataLayer', 'Loyalty', 'LANGUAGES', '$location','FRONTEND_TEMPLATES',
        function ($scope, $rootScope, $state, $timeout, $document, $q, $element, api, Util, config, user, dialog, bubble, toast, localStorageService, HubService, ORGANIZATION_TYPES, NEWSLETTER_POPUP_BEHAVIOR, DataLayer, Loyalty, LANGUAGES, $location,FRONTEND_TEMPLATES) {
            var headerCtrl = this,
                _showNewsletterTimeout,
                _isCategoriesShownWithMouseOver,
                _isCategoriesShownWithClick,
                _SHOPO_RETAILER_ID = 1184;

            $rootScope.noAvailableDeliverySlots = false;
            $rootScope.captchaNewsletterIsNotValid = false;
            angular.extend(headerCtrl, {
                user: user,
                title: config.retailer.title,
                openLoginDialog: Util.openLoginDialog,
                searchProducts: searchProducts,
                getAutoCompleteOptions: getAutoCompleteOptions,
                openContactUs: openContactUs,
                showNewsletterPopup: showNewsletterPopup,
                autoCompleteFilters: autoCompleteFilters,
                openCategory: openCategory,
                closeCategory: closeCategory,
                isOpen: isOpen,
                isCurrentCategory: isCurrentCategory,
                toggleCategoryPermanently: toggleCategoryPermanently,
                toggleCategories: toggleCategories,
                backToHub: backToHub,
                deliveryNoAvailableSlotsPopup: deliveryNoAvailableSlotsPopup,
                bypassBlock: bypassBlock,
                closeList: closeList,
                showAutocomplete: showAutocomplete,
                language: config.language.culture,
                userFullname: '', 
                isAvailableMembershipClub: false,
                membershipClubState: null,
                retailerLoyaltyClubConfig: null,
                userPoints: null,
                userLoyaltyClub: null,
                isCurrentPath: isCurrentPath,
                isInternalUrl: Util.isInternalUrl
            });
            getAutocompleteSearchText();
            //create new array for header categories - to support adding custom categories
            headerCtrl.categories = [];
            headerCtrl.loyaltyClubDriver = Loyalty.getRetailerClubDriver();
            headerCtrl.isEnableDeliveryDisclaimerPopUp = config.retailer.settings.isEnableDeliveryDisclaimerPopUp === 'true';
            headerCtrl.deliveryDisclamerPopupColor = JSON.parse(config.retailer.settings.deliveryDisclamerPopupColor || '{}');
            headerCtrl.languageId = config.language.id
            headerCtrl.deliveryDisclaimerPopUpMessage = JSON.parse(config.retailer.settings.deliveryDisclaimerPopUpMessage || '{}');
            config.initPromise.then(function() {
                headerCtrl.categories.push.apply(headerCtrl.categories, config.tree.categories);
            });
            
            if (headerCtrl.loyaltyClubDriver) {
                headerCtrl.retailerLoyaltyClubConfig = Loyalty.getRetailerDefaultClub() && Loyalty.getRetailerDefaultClub().loyaltyPages;
            }
            // init
            getUserpoints();

            user.getData().then(function (data) {
                Util.showOrganizationInactivePopup();
                if (!data) return;
                headerCtrl.userLoyaltyClub = Loyalty.getUserLoyaltyClub(data);
                if (!headerCtrl.userLoyaltyClub) return;
                if (data.loyaltyClubs.length > 0 && headerCtrl.loyaltyClubDriver) { 
                    if (!Loyalty.getRetailerClubConfig(headerCtrl.userLoyaltyClub.loyaltyClubId)) return;
                    headerCtrl.retailerLoyaltyClubConfig = Loyalty.getRetailerClubConfig(headerCtrl.userLoyaltyClub.loyaltyClubId).loyaltyPages;
                }
                if ((data.firstName + " " + data.lastName).length > 20) {
                    headerCtrl.userFullname = data.firstName.substring(0, 11) +
                    data.lastName.substring(0, 11);
                } else {
                    headerCtrl.userFullname = data.firstName + " " + data.lastName;
                }
            });
            if ($rootScope.config.retailer.settings.newsletterAllowPopup && $rootScope.config.retailer.settings.newsletterPopupBehavior == NEWSLETTER_POPUP_BEHAVIOR.ENTER_SITE && !localStorageService.getItem('newsletterPopup')) {
                user.getData().then(function (data) {
                    return !data.allowSendPromotions;
                }).catch(function () {
                    return false;
                }).then(function (doNotShow) {
                    var isShopoRetailer = config.hubRetailer && config.hubRetailer.id === _SHOPO_RETAILER_ID;
                    if (doNotShow || isShopoRetailer) {
                        return;
                    }
                    showNewsletterPopup();
                });
            }

            setNumberOfCoupons();

            function getAutocompleteSearchText() {
                var data = config.retailer.settings.searchText;
                if (data) {
                    var searchTextSetting = data;
                    if (Object.keys(searchTextSetting).length > 0) {
                        var searchTextJSON = JSON.parse(searchTextSetting);
                        var translations = {};
                        Object.keys(LANGUAGES).forEach(function(culture) {
                            translations[culture] = searchTextJSON[LANGUAGES[culture]];
                        })
                        $rootScope.searchText = translations;
                    }
                } 
            }

            $scope.$watch(
              function () {
                return config.language.culture;
              },
              function (newCulture, oldCulture) {
                headerCtrl.language = newCulture;
              }
            );
            Util.currentScopeListener($scope, $rootScope.$on('login', setNumberOfCoupons));
            Util.currentScopeListener($scope, $rootScope.$on('logout', setNumberOfCoupons));
            Util.currentScopeListener($scope, $rootScope.$on('numberOfCouponsChanged', function (event, numberOfCoupons) {
                headerCtrl.numberOfCoupons = numberOfCoupons;
            }));
            Util.currentScopeListener($scope, $rootScope.$on('login', function() {
                Util.showOrganizationInactivePopup();
                getUserpoints();
            }));
            Util.currentScopeListener($scope, $rootScope.$on('login', function () {
                user.getData().then(function (data) {
                    if (!data) return;
                    headerCtrl.userLoyaltyClub = Loyalty.getUserLoyaltyClub(data);
                    if (!headerCtrl.userLoyaltyClub) return;
                    if (data.loyaltyClubs.length > 0 && headerCtrl.loyaltyClubDriver) { 
                        if (!Loyalty.getRetailerClubConfig(headerCtrl.userLoyaltyClub.loyaltyClubId)) return;
                        headerCtrl.retailerLoyaltyClubConfig = Loyalty.getRetailerClubConfig(headerCtrl.userLoyaltyClub.loyaltyClubId).loyaltyPages;
                    }
                    if ((data.firstName + " " + data.lastName).length > 20) {
                      headerCtrl.userFullname = data.firstName.substring(0, 11) +
                        data.lastName.substring(0, 11);
                    } else {
                      headerCtrl.userFullname = data.firstName + " " + data.lastName;
                    }
                });
            }));

            Util.currentScopeListener($scope, $rootScope.$on('cart.lines.add.click', function () {
                if($rootScope.noAvailableDeliverySlots) {
                    Util.showDeliveryNoAvailableSlotsPopup();
                }
            }));

            setHubData();
            _setMembershipClubState();

            function setNumberOfCoupons() {
                if (user.session.userId && $rootScope.config.retailer.settings.enablePersonalizedCoupons) {
                    api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/branches/:bid/users/:uid/coupons',
                        params: {extended: true, countonly: true}
                    }).then(function (couponsData) {
                        var coupons = couponsData.coupons || couponsData;

                        Util.setGlobalCouponRedemptions(couponsData.coupons);

                        headerCtrl.numberOfCoupons = coupons.length;
                        $rootScope.$emit("numberOfCouponsHomepage", coupons.length);

                        $rootScope.userCouponsById = Util.convertArrayToObject(coupons, 'id');
                        $rootScope.couponsWithPoints = coupons.filter(function(coupon) {
                            return coupon.pointrequirement;
                        }).map(function(coupon) {
                            return coupon.id;
                        });
                    });
                } else {
                    delete headerCtrl.numberOfCoupons;
                    $rootScope.userCouponsById = null;
                }
            }

            function searchProducts($event) {
                if (!headerCtrl.searchQuery) {
                    return;
                }

                if ($event && $event.type === 'click') {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Magnifying Glass in Search'}});
                }

                if ($rootScope.linksList && $rootScope.linksList.length) {
                    var link = $rootScope.linksList.filter(function (link) {
                        return link.text === headerCtrl.searchQuery.trim();
                    })[0];

                    if (link && link.href) {
                        return Util.goToHref(link.href);
                    }
                }

                $state.go('app.searchProducts', {
                    query: headerCtrl.searchQuery,
                    filter: ($event && $event.filter ? $event.filter.id || $event.filter : null),
                    filters: undefined,
                    sort: undefined,
                    title: undefined
                });

                document.querySelector('body').focus();
            }

            function getAutoCompleteOptions() {
                if (!headerCtrl.searchQuery) {
                    return [];
                }

                return api.request({
                    url: '/v2/retailers/:rid/branches/:bid/products/suggest',
                    method: 'GET',
                    params: {
                        size: 6,
                        query: headerCtrl.searchQuery
                    }
                }).then(function (data) {
                    return data.options;
                });
            }

            function getUserpoints() {
                if (headerCtrl.loyaltyClubDriver) {
                    user.getData().then(function (data) {
                        if (!data) return;
                        headerCtrl.userLoyaltyClub = Loyalty.getUserLoyaltyClub(data);
                        if (!headerCtrl.userLoyaltyClub) return;
                        headerCtrl.activeCashbackLoyalty = Loyalty.getRetailerClubConfig(headerCtrl.userLoyaltyClub.loyaltyClubId);
                        if (!headerCtrl.activeCashbackLoyalty) return;
                        if (headerCtrl.activeCashbackLoyalty.pointsToCashback) {
                            headerCtrl.userCashbackLoyalty = data.loyaltyClubs.length && data.loyaltyClubs.find(function (club) {
                            return club.loyaltyClubDriverId === headerCtrl.loyaltyClubDriver.loyaltyClubDriverId;
                            });
                        }
                        if(headerCtrl.userCashbackLoyalty) {
                            Util.getUserCashbackPoints(headerCtrl.userCashbackLoyalty.id).then(function (response) {
                                headerCtrl.userPoints = response.points || 0;
                            });
                        }
                    })
                }
            }
            
            function openCategory(category) {
                if (headerCtrl.hasOpenPermanently || !category) {
                    return;
                }

                headerCtrl.hasOpenCategory = category.open = category.firstOpen = true;
            }

            function closeCategory(category) {
                headerCtrl.hasOpenCategory = category.open = false;
                angular.forEach(headerCtrl.categories, function (category) {
                    headerCtrl.hasOpenCategory = headerCtrl.hasOpenCategory || category.open;
                });
            }

            function isOpen(category) {
                if (!category) {
                    return false;
                }

                return category.open || category.openPermanently;
            }

            function isCurrentCategory(categoryId) {
                return $rootScope.currentCategory && $rootScope.currentCategory[$rootScope.currentCategory.length - 1].id == categoryId;
            }

            function toggleCategoryPermanently(categoryToToggle) {
                headerCtrl.hasOpenCategory = headerCtrl.hasOpenPermanently = false;
                angular.forEach(headerCtrl.categories, function (category) {
                    if (category == categoryToToggle) {
                        headerCtrl.hasOpenPermanently = category.openPermanently = !category.openPermanently;
                        if (!category.firstOpen && headerCtrl.hasOpenPermanently) {
                            category.firstOpen = true;
                        }
                        category.open = false;
                    } else {
                        category.openPermanently = category.open = false;
                    }
                });

                return $q.resolve();
            }

            function toggleCategories(isClick, value) {
                //when it's a hover toggle and is shown already from a click - do nothing
                if (!isClick && _isCategoriesShownWithClick) {
                    _isCategoriesShownWithMouseOver = false;
                    return;
                }

                //when it's a click toggle mark the hover as false to reset it
                if (isClick) {
                    _isCategoriesShownWithMouseOver = false;
                }

                if (isClick) {
                    headerCtrl.isCategoriesShown = angular.isUndefined(value) ? !_isCategoriesShownWithClick : value;
                    _isCategoriesShownWithClick = headerCtrl.isCategoriesShown;

                    // if (headerCtrl.isCategoriesShown) {
                    //     $timeout(function () {
                    //         var category = document.querySelector('.categories-wrapper .categories .category .category-name');
                    //         category && category.focus();
                    //     },0);
                    // }
                } else {
                    headerCtrl.isCategoriesShown = angular.isUndefined(value) ? !_isCategoriesShownWithMouseOver : value;
                    _isCategoriesShownWithMouseOver = headerCtrl.isCategoriesShown;
                }
            }

            function backToHub() {
                return HubService.backToHub(config.hubRetailer.domainName);
            }

            function openContactUs() {
                Util.openContactUs();
            }

            function deliveryNoAvailableSlotsPopup() {
                Util.showDeliveryNoAvailableSlotsPopup(true);
            }

            function bypassBlock(id) {
                var element = document.getElementById(id);
                !!element && element.focus();
            }

            function showNewsletterPopup() {
                var newsletterBubble = document.querySelector('.newsletter-bubble');
                if (newsletterBubble) {
                    return;
                }

                DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Open Newsletter'}});

                _showNewsletterTimeout && $timeout.cancel(_showNewsletterTimeout);

                _showNewsletterTimeout = $timeout(function () {
                    return bubble.show({
                        controller: ['$scope', 'hide', '$timeout', 'DataLayer', function ($scope, hide, $timeout, DataLayer) {
                            $scope.closePopup = closePopup;
                            $scope.registerNewsletterUser = registerNewsletterUser;
                            $scope.hubData = headerCtrl.hubData;

                            $timeout(function () {
                                var newsletterBubbleElement = document.querySelector('.newsletter-bubble');
                                newsletterBubbleElement && newsletterBubbleElement.focus();
                            });

                            function closePopup() {
                                localStorageService.setItem('newsletterPopup', new Date());
                                hide();
                            }

                            function registerNewsletterUser(form, event) {
                                var formElement = event.target || event.srcElement || event.path[0];
                                if (!$rootScope.newsletterEmailUser) {
                                    return;
                                }
                                if(form.$invalid) {
                                    angular.forEach(form.$error.required, function(field) {
                                        if (field.$$attr.id === 'captcha_hidden_input') {
                                            $rootScope.captchaNewsletterIsNotValid = true;
                                        }
                                    });
                                    return;
                                }

                                DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Join Newsletter'}});

                                user.register({
                                    isNewsletter: true,
                                    email: $rootScope.newsletterEmailUser
                                }).then(function () {
                                    toast.show({
                                        timeout: 3000,
                                        content: '{{\'Success\'| translate}}'
                                    });
                                    hide();
                                    localStorageService.setItem('newsletterPopup', new Date());
                                    if(user.data.email === $rootScope.newsletterEmailUser) {
                                        user.data.allowSendPromotions = true;
                                    }
                                    $rootScope.newsletterEmailUser = '';
                                    if($scope.hubData) {
                                        $scope.hubData.allowSendPromotions = false
                                    }
                                }).catch(function (res) {
                                    Util.setServerErrorToForm(form, formElement, res);
                                });
                            }
                        }],
                        fixed: true,
                        position: {
                            bottom: -12,
                            left: 0
                        },
                        showClass: 'the-newsletter-bubble',
                        nextTo: document.querySelector('.newsletter'),
                        templateUrl: 'template/views/newsletter-bubble/index.html'
                    });
                }, 1000);
            }

            function setHubData() {
                if (config.hubRetailer && config.hubRetailer.id) {
                    HubService.getData(config.hubRetailer.id).then(function (hub) {
                        headerCtrl.hubData = hub;
                    });
                }
            }

            function autoCompleteFilters() {
                if (!$rootScope.currentCategory || !$rootScope.currentCategory.length || isNaN($rootScope.currentCategory[0].id)) {
                    return ['all', 'specials'];
                }

                return ['all', $rootScope.currentCategory[0], 'specials'];
            }

            function showAutocomplete() {
                $rootScope.isShowAutocomplete = true;
            }

            function closeList(){
                var domElement = angular.element($element[0].querySelector('.search-input'));
                domElement[0].blur();
            }

            function _isOrganizationObligoType() {
                return (
                    user.data &&
                    user.data.organization &&
                    user.data.organization.organizationType ===
                    ORGANIZATION_TYPES.OBLIGO
                );
            }

            function _setMembershipClubState() {
              headerCtrl.isAvailableMembershipClub =
                config.retailer.loyaltyClub && !_isOrganizationObligoType();
              var loyaltyClubDriver =
                  !!config.retailer.loyaltyClubDrivers &&
                  config.retailer.loyaltyClubDrivers[0],
                isPremiumLoyaltyClub =
                  Util.isLoyaltyPremiumPackageEnabled() && !!loyaltyClubDriver;
              headerCtrl.membershipClubState = {
                state: isPremiumLoyaltyClub
                  ? "premiumLoyaltyClub"
                  : !!loyaltyClubDriver &&
                    loyaltyClubDriver.clientConfig.extendedLoyaltyClub
                  ? "extendedLoyalty"
                  : "loyalty",
                stateParams: isPremiumLoyaltyClub
                  ? JSON.stringify({
                      loyaltyClubDriverId:
                        loyaltyClubDriver.loyaltyClubDriverId,
                    })
                  : null,
              };
            }

            function isCurrentPath(path) {
                var curentPath = $location.$$path;
                if (path.startsWith('/tags')) {
                    path = path.replace('/tags', '/product-tags');
                }
                return curentPath.includes(path);
            }
        }])

        //todo: this controller should be only in the prutah template
        .controller('HeaderPrutahCtrl', ['$scope', '$rootScope', '$timeout', '$state', '$q', 'Config', 'Util', 'Specials', 'SpDeliveryTimesService', 'Cart', 'SP_SERVICES',
            function($scope, $rootScope, $timeout, $state, $q, Config, Util, specials, SpDeliveryTimesService, Cart, SP_SERVICES) {
            var headerCtrl = $scope.headerCtrl,
                DEFAULT_SHOWN_SUB_SUB_CATEGORIES = 10,
                MENU_SCROLL_BAR_WIDTH = 8,
                _openCategoryTimeout;

            var _originalFunctions = {
                openCategory: headerCtrl.openCategory,
                closeCategory: headerCtrl.closeCategory,
                toggleCategoryPermanently: headerCtrl.toggleCategoryPermanently,
            };

            headerCtrl.shownSubSubCategories = DEFAULT_SHOWN_SUB_SUB_CATEGORIES;
            headerCtrl.getHrefFromState = getHrefFromState;
            headerCtrl.openCategory = openCategory;
            headerCtrl.closeCategory = closeCategory;
            headerCtrl.toggleCategoryPermanently = toggleCategoryPermanently;
            headerCtrl.toggleMoreSubSubCategories = toggleMoreSubSubCategories;
            headerCtrl.nextDelivery = {moreSlots: {}};
            //add custom categories
            $q.all({
                specials: specials.getSpecials(GET_SPECIALS_PARAMS),
                filters: specials.getFilters()
            }).then(function (results) {
                if (!!results.specials.total) {
                    var specialsCategory = {
                        id: 'specials',
                        isCustomCategory: true,
                        action: goToSpecials,
                        name: 'specials_and_benefits_title',
                        iconImage: 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/specials-icon.png',
                        iconSelectedImage: 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/specials-icon-white.png'
                    };
                    if (results.filters && results.filters.categories && results.filters.categories.length) {
                        specialsCategory.iconImage = Config.salesImageUrl;
                        specialsCategory.iconSelectedImage = 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/specials-icon-white.png';
                        specialsCategory.bySpecialCategories = true;
                        specialsCategory.subCategories = _generateSaleCategories(results.filters.categories);
                    }
                    headerCtrl.categories.unshift(specialsCategory);
                    $rootScope.isThereSpecialsCategory = true;
                    Util.createSearchList();
                }

                headerCtrl.categories.unshift({
                    id: 'myItems',
                    isCustomCategory: true,
                    name: 'My lists',
                    iconImage: 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/my-items-icon.png',
                    iconSelectedImage: 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/my-items-icon-white.png',
                    subCategories: [{
                        subCategories: [{
                            id: 'ordersHistory',
                            state: 'app.ordersHistory',
                            name: 'Previous Orders'
                        }, {
                            id: 'shopLists',
                            state: 'app.shopLists',
                            name: 'My Shopping Lists'
                        }]
                    }]
                });
                $rootScope.isThereSmartList = Config.retailer.isSmartListDisplayEnabled && headerCtrl.categories && headerCtrl.categories[0] && headerCtrl.categories[0].subCategories && headerCtrl.categories[0].subCategories[0];
                if($rootScope.isThereSmartList) {
                    Array.isArray(headerCtrl.categories[0].subCategories[0].subCategories) &&
                    headerCtrl.categories[0].subCategories[0].subCategories.unshift({
                        id: 'smartList',
                        state: 'app.smartList',
                        name: 'The products I usually purchase'
                    });
                }
            });

            function _generateSaleCategories(categories) {
                var subCategories = [];
                categories = [{
                    id: null,
                    name: 'All Specials'
                }].concat(categories);
                angular.forEach(Util.divideArray(categories, 5), function(subSubCategories, index) {
                    subCategories.push({
                        id: index,
                        subCategories: subSubCategories
                    });
                });
                return subCategories;
            }

            function getHrefFromState(state, params) {
                return $state.href(state, params);
            }

            function openCategory(category) {
                _openCategoryTimeout && $timeout.cancel(_openCategoryTimeout);
                _openCategoryTimeout = $timeout(function () {
                    _originalFunctions.openCategory(category);
                    _bindSubCategoriesMenuScroll();
                    _setSubCategoriesLeft();
                }, 150);
            }

            function closeCategory(category) {
                _openCategoryTimeout && $timeout.cancel(_openCategoryTimeout);
                _originalFunctions.closeCategory(category);
                _setSubCategoriesLeft();
            }

            function toggleCategoryPermanently(categoryToToggle) {
                var isFirstTimeOpen = categoryToToggle && categoryToToggle.firstOpen;
                angular.forEach(headerCtrl.categories, function (category) {
                    if (category.openPermanently && category.showMore) {
                        _cancelMoreSubSubCategories(category);
                    }
                });

                _originalFunctions.toggleCategoryPermanently(categoryToToggle).then(function() {
                    if (!isFirstTimeOpen && headerCtrl.hasOpenPermanently) {
                        _bindSubCategoriesMenuScroll();
                    }

                    _setSubCategoriesLeft();
                });
            }

            function toggleMoreSubSubCategories(category, event) {
                if (category.showMore) {
                    _cancelMoreSubSubCategories(category);
                } else {
                    category.showMore = true;
                    headerCtrl.shownSubSubCategories = Infinity;
                    var windowHeight = window.innerHeight - document.querySelector('body > header').clientHeight - 20,
                        newHeight = _getFakeCategoryHeight([category]);
                    if (newHeight > windowHeight) {
                        category.subCategoryHeight = windowHeight;
                        category.hasVerticalScrollbar = true;
                    } else {
                        category.subCategoryHeight = newHeight;
                        category.hasVerticalScrollbar = false;
                    }
                }

                if (!category.openPermanently) {
                    toggleCategoryPermanently(category);
                } else {
                    _setSubCategoriesLeft();
                }

                event && event.stopPropagation();
            }

            function goToSpecials() {
                $state.go('app.specials');
            }

            function _setSubCategoriesLeft() {
                $timeout(function () {
                    angular.element(document.querySelectorAll('.sub-categories.right-0')).removeClass('right-0');
                    var isRtl = Config.language.direction === 'rtl',
                        openCategory = angular.element(document.querySelector('.category.open')),
                        openedCategoryContent = angular.element(document.querySelector('.category.open > .category-content')),
                        openedCategorySubs = angular.element(document.querySelector('.category.open > .sub-categories'));
                    var openedCategorySubsTitleWidth = angular.element(document.querySelector('.category.open > .sub-categories > .category-title-wrapper > .category-title')).prop('clientWidth') || 0;
                    if (openedCategorySubs.length) {
                        var defaultMargin = Util.getCurrentStyleProp(openedCategorySubs, 'margin-' + (isRtl ? 'left' : 'right'), true),
                            subCategoriesWidth = defaultMargin,
                            openedCategoryContentWidth = openedCategoryContent.prop('clientWidth') || 0;
                        angular.forEach(openedCategorySubs[0].querySelectorAll('.sub-category'), function (subCategoryElement) {
                            subCategoriesWidth += subCategoryElement.offsetWidth;
                        });
                        subCategoriesWidth = subCategoriesWidth > openedCategoryContentWidth ? subCategoriesWidth : openedCategoryContentWidth;
                        subCategoriesWidth = subCategoriesWidth > openedCategorySubsTitleWidth ? subCategoriesWidth : openedCategorySubsTitleWidth;
                        var categoryContentOffsetLeft = openedCategoryContent.prop('offsetLeft'),
                            categoryContentOffset = isRtl ? window.innerWidth - (categoryContentOffsetLeft + openedCategoryContentWidth) : categoryContentOffsetLeft,
                            categorySubsReach = categoryContentOffset + subCategoriesWidth;
                        if (categorySubsReach > window.innerWidth) {
                            openedCategorySubs.addClass('right-0').css({
                                'min-width': (openedCategorySubsTitleWidth > openedCategoryContentWidth ? openedCategorySubsTitleWidth : openedCategoryContentWidth) + 'px',
                                'max-width': (document.querySelector('.categories').offsetWidth - (defaultMargin * 2)) + 'px'
                            });
                        } else {
                            openedCategorySubs.css({'min-width': subCategoriesWidth + 'px', 'max-width': ''});
                        }

                        var fixedTitles = openedCategorySubs[0].querySelectorAll('.sub-categories-fixed-titles > .sub-category-link'),
                            totalWidth = 0;
                        angular.forEach(openedCategorySubs[0].querySelectorAll('.sub-categories-content-wrapper > .sub-categories-content > .sub-category'), function (element, index) {
                            var width = element.getBoundingClientRect().width;
                            totalWidth += width;
                            angular.element(fixedTitles[index]).css({'width': width + 'px'});
                        });
                        angular.element(openedCategorySubs[0].querySelectorAll('.sub-categories-content-wrapper > .sub-categories-content, .sub-categories-fixed-titles')).css({
                            width: totalWidth + (headerCtrl.shownSubSubCategories > DEFAULT_SHOWN_SUB_SUB_CATEGORIES ? MENU_SCROLL_BAR_WIDTH : 0) + 'px'
                        });
                    }
                    _setLinePosition(openedCategoryContent);
                    openCategory.addClass('shown');
                });
            }

            function _setLinePosition(openCat) {
                openCat = openCat || angular.element(document.querySelector('.category.open > .category-content'));
                var lineElement = angular.element(document.querySelector('.categories > .line'));
                lineElement.css({
                    'left': openCat.length ? openCat.prop('offsetLeft') + 'px' : '0',
                    'width': openCat.length ? openCat.prop('clientWidth') + 'px' : '0'
                });
            }

            function _bindSubCategoriesMenuScroll() {
                $timeout(function () {
                    var element = document.querySelector('.category.open > .sub-categories > .sub-categories-content-wrapper > .sub-categories-content');
                    if (!element) {
                        return;
                    }
                    angular.element(element).bind('scroll', _onSubCategoriesMenuScroll);
                    _onSubCategoriesMenuScroll({target: element});
                }, 10);
            }

            function _onSubCategoriesMenuScroll(event) {
                var subCategoriesContent = event.target || event.srcElement || (event.path && event.path[0]),
                    subCategoriesWrapper = subCategoriesContent;
                do {
                    subCategoriesWrapper = subCategoriesWrapper.parentNode || subCategoriesWrapper.parentElement;
                } while (subCategoriesWrapper && !angular.element(subCategoriesWrapper).hasClass('sub-categories'));

                var fixedTitlesWrapper = subCategoriesWrapper.querySelector('.sub-categories-fixed-titles');
                if (fixedTitlesWrapper && subCategoriesContent) {
                    fixedTitlesWrapper.scrollLeft = subCategoriesContent.scrollLeft;
                }
            }

            function _cancelMoreSubSubCategories(category) {
                category.showMore = false;
                category.hasVerticalScrollbar = false;
                headerCtrl.shownSubSubCategories = DEFAULT_SHOWN_SUB_SUB_CATEGORIES;
                delete category.subCategoryHeight;
            }

            function _getNextDeliveryArea() {
                Config.initPromise.then(function () {
                    return SpDeliveryTimesService.getNextDeliveryTimes(headerCtrl.nextDelivery, Util.getUserAddress, Config.getBranchArea(), Cart.serverCartId);
                }).then(function(result) {
                    Cart.forceUpdate().then(function () {
                        var selectedStore = {};
                        try {
                            selectedStore = $rootScope.config.getBranchArea() || {}
                        } catch (e) {
                            // do nothing
                        }
                        angular.forEach(Cart.lines, function (line) {
                            if (line.product && selectedStore.deliveryProduct_Id == line.product.id &&
                                line.type == SP_SERVICES.CART_LINE_TYPES.DELIVERY && line.id !== '-10') {
                                Config.deliveryFeeLine = line;
                                Config.deliveryAreaPrice = line.product && line.product.branch && line.product.branch.regularPrice;;
                            }
                        })
                    });
                    $rootScope.$emit('user.fetchArea.finish');
                });
            }

            $rootScope.$on('store.changed', _getNextDeliveryArea);

            function _getFakeCategoryHeight(categories) {
                var subSubCategoriesLength = 0,
                    showMore = false;
                angular.forEach(categories || headerCtrl.categories, function (category) {
                    angular.forEach(category.subCategories, function (subCategory) {
                        subSubCategoriesLength = subCategory.subCategories.length > subSubCategoriesLength ? subCategory.subCategories.length : subSubCategoriesLength;
                    });
                    showMore = showMore || category.showMore;
                });
                var fakeCategory = _getFakeCategory(subSubCategoriesLength, showMore);
                var height = fakeCategory.content.prop('clientHeight');
                fakeCategory.element.remove();
                return height;
            }

            function _getFakeCategory(length, showMore) {
                var subSubElements = '';
                for (var i = 0; i < length && i < (headerCtrl.shownSubSubCategories + 1); i++) {
                    subSubElements += '<a class="sub-sub-category" href="#">Test name</a>';
                }
                var title = angular.element('<div class="category-title">Test name</div>'),
                    content = angular.element('' +
                        '<div class="sub-categories-content-wrapper">' +
                        '<div class="sub-categories-content">' +
                        '<div class="sub-category">' +
                        '<a class="sub-category-link" href="#">Test name</a>' +
                        subSubElements +
                        '</div>' +
                        '</div>' +
                        '</div>'),
                    subCategories = angular.element('<div class="sub-categories"></div>'),
                    fakeElement = angular.element('' +
                        '<div class="category open fake current' + (showMore ? ' more-shown' : '') + '">' +
                        '<div class="category-content"><span class="for-vertical-align"></span><div class="name">Test</div></div>' +
                        '</div>');
                subCategories.append(angular.element('<div class="category-title-wrapper">Test name</div>').append(title)).append(content);
                fakeElement.append(subCategories);
                fakeElement.append(angular.element('<div class="show-less"></div>'));
                angular.element(document.querySelectorAll('header > .menu > .categories')).append(fakeElement);
                return {element: fakeElement, content: content};
            }

            $rootScope.$on('$stateChangeSuccess', function () {
                headerCtrl.hasOpenPermanently = false;
                if (headerCtrl.categories) {
                    angular.forEach(headerCtrl.categories, function (category) {
                        category.openPermanently = category.open = false;
                    });
                }
                _setLinePosition();
            });

            $rootScope.$on('document.click', function (data, event) {
                if (headerCtrl.hasOpenPermanently) {
                    var $elm = angular.element(event.target || event.srcElement || event.toElement),
                        tagName = ($elm.prop('tagName') || '').toUpperCase();
                    while (tagName && tagName !== 'BODY' && (tagName !== 'LI' || !$elm.hasClass('category'))) {
                        $elm = $elm.parent();
                        tagName = ($elm.prop('tagName') || '').toUpperCase();
                    }
                    if (tagName !== 'LI' || !$elm.hasClass('category')) {
                        headerCtrl.toggleCategoryPermanently();
                        $scope.$applyAsync();
                    }
                }
                _setLinePosition();
            });
        }])

        //todo: this controller should be only in the kikar template
        .controller('HeaderKikarCtrl', [
            '$scope', '$rootScope', '$timeout', '$q', 'Config', 'Util', 'User', 'SpDeliveryTimesService', 'Specials', 'SP_SERVICES', 'DELIVERY_TIMES_TYPES', '$location', 'Cart', 'DataLayer',
            function ($scope, $rootScope, $timeout, $q, Config, Util, User, SpDeliveryTimesService, Specials, SP_SERVICES, DELIVERY_TIMES_TYPES, $location, Cart, DataLayer) {
                var headerCtrl = $scope.headerCtrl,
                    _nextDeliveryMouseMoveTimeout,
                    _closeCategoryTimeout,
                    _openCategoryTimeout;

                angular.extend(headerCtrl, {
                    nextDelivery: {moreSlots: {}},
                    showLoyaltyShortcut: Config.retailer.loyaltyClub,
                    isPremiumLoyaltyClub: Util.isLoyaltyPremiumPackageEnabled() && !!Config.retailer.loyaltyClubDrivers && Config.retailer.loyaltyClubDrivers.length === 1 && Config.retailer.loyaltyClubDrivers[0],
                    toggleNextDeliveryMoreDetails: toggleNextDeliveryMoreDetails,
                    openCategoryAndCloseTheOpen: openCategoryAndCloseTheOpen,
                    closeOpenCategory: closeOpenCategory,
                    setCurrentMoreSlots: setCurrentMoreSlots,
                    DELIVERY_TYPES: SP_SERVICES.DELIVERY_TYPES
                });

                _getNextDeliveryArea();
                _getSpecials();
                Specials.getFilters().then(function () {
                    Util.createSearchList();
                });

                function openCategoryAndCloseTheOpen(category) {
                    closeOpenCategory();
                    _openCategoryTimeout && $timeout.cancel(_openCategoryTimeout);
                    _openCategoryTimeout = $timeout(function () {
                        headerCtrl.openCategory(category);
                    }, 150);
                }

                function closeOpenCategory() {
                    _closeCategoryTimeout && $timeout.cancel(_closeCategoryTimeout);
                    _closeCategoryTimeout = $timeout(function() {
                        angular.forEach(headerCtrl.categories, function(openCategory) {
                            if (openCategory.open) {
                                headerCtrl.closeCategory(openCategory);
                            }
                        });
                    }, 150);
                }

                function setCurrentMoreSlots(areasSlots, deliveryTypeId) {
                    SpDeliveryTimesService.setNextDeliveryCurrentSlots(headerCtrl.nextDelivery, areasSlots, deliveryTypeId);
                    headerCtrl.selectedDeliveryType = deliveryTypeId;
                }

                function toggleNextDeliveryMoreDetails() {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Next Delivery List'}});
                    headerCtrl.nextDelivery.showMoreDetails = !headerCtrl.nextDelivery.showMoreDetails;
                    if (headerCtrl.nextDelivery.showMoreDetails) {
                        angular.element(document.body || document.querySelector('body')).bind('mousedown', _clickOnBodyToCloseMoreDetails);
                    } else {
                        angular.element(document.body || document.querySelector('body')).unbind('mousedown', _clickOnBodyToCloseMoreDetails);
                    }
                }

                function _nextDeliveryOnMouseMove() {
                    _nextDeliveryMouseMoveTimeout = null;
                    angular.element(document.body || document.querySelector('body')).unbind('mousemove', _nextDeliveryOnMouseMove);
                    _getNextDeliveryArea();
                }

                function _getUserAddress() {
                    return User.getData().then(function (data) {
                        var address = data.addresses[0];
                        if (!address || !address.text1 || !address.city) {
                            return $q.reject(null);
                        }

                        if (Config.isZipCodeArea && Config.area && !address.zipCode) {
                            address.zipCode = Config.area;
                        }

                        return {
                            address: {
                                text1: address.text1,
                                street: address.street,
                                houseNumber: address.houseNumber,
                                entry: address.entry,
                                city: address.city,
                                zipCode: address.zipCode
                            }
                        }
                    })
                }

                function _getNextDeliveryArea(cancelTimeout) {
                    // to prevent the area dialog from being opened when trying to get the user's area
                    Util.fetchingUserArea = true;

                    if (cancelTimeout && !!_nextDeliveryMouseMoveTimeout) {
                        $timeout.cancel(_nextDeliveryMouseMoveTimeout);
                        _nextDeliveryMouseMoveTimeout = null;
                    }

                    if (!!_nextDeliveryMouseMoveTimeout) {
                        return;
                    }

                    Config.initPromise.then(function () {
                        return SpDeliveryTimesService.getNextDeliveryTimes(headerCtrl.nextDelivery, Util.getUserAddress, Config.getBranchArea(), Cart.serverCartId);
                    }).then(function(result) {
                        if (!result.nextTime ||
                            (!result.nextTime.isToday && result.nextTime.type !== DELIVERY_TIMES_TYPES.DELIVERY_WITHIN_HOURS)) {
                            Util.showNoDeliveriesTodayPopup();
                        }

                        $rootScope.noAvailableDeliverySlots = result.noAvailableDeliverySlots;
                    }).catch(function() {
                        if (headerCtrl.nextDelivery.times) {
                            headerCtrl.nextDelivery.times.splice(0);
                        }
                    }).finally(function () {
                        Util.fetchingUserArea = false;
                        $rootScope.$emit('user.fetchArea.finish');
                        if(!$rootScope.preventCartUpdate) {
                            Cart.forceUpdate().then(function () {
                                var selectedStore = {};
                                try {
                                    selectedStore = $rootScope.config.getBranchArea() || {}
                                } catch (e) {
                                    // do nothing
                                }
                                angular.forEach(Cart.lines, function (line) {
                                    if (line.product && selectedStore.deliveryProduct_Id == line.product.id &&
                                        line.type == SP_SERVICES.CART_LINE_TYPES.DELIVERY && line.id !== '-10') {
                                        Config.deliveryFeeLine = line;
                                        Config.deliveryAreaPrice = line.product && line.product.branch && line.product.branch.regularPrice;
                                    }
                                })
                            });
                        }

                        if (headerCtrl.nextDelivery.moreSlotsTypes && headerCtrl.nextDelivery.moreSlotsTypes.length) {
                            setCurrentMoreSlots(headerCtrl.nextDelivery.moreSlots[headerCtrl.nextDelivery.currentNextDeliveryTypeId], headerCtrl.nextDelivery.currentNextDeliveryTypeId);
                        }
                    });

                    _nextDeliveryMouseMoveTimeout = $timeout(function () {
                        angular.element(document.body || document.querySelector('body')).bind('mousemove', _nextDeliveryOnMouseMove);
                    }, 240000); // 4 minutes
                }

                function _clickOnBodyToCloseMoreDetails(event) {
                    var target = angular.element(event.target || event.srcElement || event.path[0]);
                    while (target.length && !target.hasClass('next-delivery-wrapper')) {
                        target = angular.element(target.prop('parentNode') || target.prop('parentElement'));
                    }
                    if (target.hasClass('next-delivery-wrapper')) {
                        return;
                    }

                    toggleNextDeliveryMoreDetails();
                    $scope.$applyAsync();
                }

                function _getSpecials() {
                    Specials.getSpecials(GET_SPECIALS_PARAMS).then(function (specials) {
                        headerCtrl.displaySpecials = !!specials.total;
                    });
                }

                Util.currentScopeListener($scope, $rootScope.$on('logout', function () {
                    _getNextDeliveryArea(true);
                }));

                Util.currentScopeListener($scope, $rootScope.$on('login', function () {
                    _getNextDeliveryArea(true);
                }));

                Util.currentScopeListener($scope, $rootScope.$on('config.branchAreaId.change', function () {
                    _getNextDeliveryArea(true);
                }));

                Util.currentScopeListener($scope, $rootScope.$on('select.main.bread.crumbs', function (event, category) {
                    headerCtrl.toggleCategoryPermanently(category);
                    headerCtrl.toggleCategories(true, true);
                    headerCtrl.isBreadBrumbs = true;

                    if (category && category.id && $location.$$path) {
                        var path = $location.$$path.split('/')
                        if(path && path.length) {
                            var mainCategoryId = path.some(function (id) {
                                return id == category.id;
                            })
                            var subCategory = category.subCategories.find(function (subCategory) {
                                return path.find(function (id) {
                                    return id == subCategory.id;
                                })
                            })

                            if (!mainCategoryId && subCategory) {
                                var index = path.indexOf(subCategory.id.toString());
                                path[index] = category.id;
                                $location.path(path.join('/')).search($location.search());
                                setTimeout(function () {
                                    headerCtrl.toggleCategories(true, true);
                                    headerCtrl.toggleCategoryPermanently(category);
                                }, 0)
                            }
                        }
                    }
                }));

                Util.currentScopeListener($scope, $rootScope.$on('$stateChangeSuccess', function () {
                    headerCtrl.isCategoriesShown = headerCtrl.hasOpenCategory = headerCtrl.hasOpenPermanently = false;
                    if (headerCtrl.categories) {
                        angular.forEach(headerCtrl.categories, function (category) {
                            category.openPermanently = category.open = false;
                        });
                    }
                }));

                Util.currentScopeListener($scope, $rootScope.$on('document.click', function (data, event) {
                    if (headerCtrl.isBreadBrumbs) {
                        headerCtrl.isBreadBrumbs = false;
                        return;
                    }
                    if (headerCtrl.isCategoriesShown || headerCtrl.hasOpenPermanently) {
                        var $elm = angular.element(event.target || event.srcElement || event.toElement),
                            tagName = ($elm.prop('tagName') || '').toUpperCase();
                        while (tagName && tagName != 'BODY' && (tagName != 'NAV' || !$elm.hasClass('categories-wrapper')) && (tagName != 'DIV' || !$elm.hasClass('categories-title'))) {
                            $elm = $elm.parent();
                            tagName = ($elm.prop('tagName') || '').toUpperCase();
                        }
                        if (tagName != 'NAV' || !$elm.hasClass('categories-wrapper')) {
                            headerCtrl.toggleCategoryPermanently();
                            if (tagName != 'DIV' || !$elm.hasClass('categories-title')) {
                                headerCtrl.isCategoriesShown = false;
                            }
                            $scope.$applyAsync();
                        }
                    }
                }));
            }]);
})(angular, app);
