(function (angular, app) {
    'use strict';

    app.service('User', [
        '$q', '$rootScope', '$location', '$timeout', '$injector', 'LocalStorage', 'Api', 'Dialog', 'Config',
        'SEARCH_SORT_BOOST_TYPES', 'USER_VERIFICATION_STATUS', 'ORGANIZATION_TYPES', 'POLICY_ENFORCEMENT_MODES', 'USER_ERRORS', 'BRANCH_DELIVERY_TYPES',
        function ($q, $rootScope, $location, $timeout, $injector, localStorageService, api, dialog, config,
                  SEARCH_SORT_BOOST_TYPES, USER_VERIFICATION_STATUS, ORGANIZATION_TYPES, POLICY_ENFORCEMENT_MODES, USER_ERRORS, BRANCH_DELIVERY_TYPES) {
            var self = this,
                Util;

            self.session = localStorageService.getItem('session') || {};
            self.data = {};

			var getDataPromise,
                _isFirstTime = true;

			self.getPurchasesSortBoost = getPurchasesSortBoost;
            self.isVerified = isVerified;
            self.isOrganizationBranch = isOrganizationBranch;
            self.resendActivationLink = resendActivationLink;
            self.setUserArea = setUserArea;
            self.validateResetCode = validateResetCode;
            self.getReplacementSuggestions = getReplacementSuggestions;
            self.updateReplacementSuggestions = updateReplacementSuggestions;
            self.getTypeOfOTPFromCredentials = getTypeOfOTPFromCredentials

            self.getAuthorizationToken = function () {
                if (!self.session || !self.session.token) {
                    return;
                }
                return 'Bearer ' + self.session.token;
            };
            self.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty = function(){
                return Boolean(config.retailer.loyaltyClubDrivers &&
                config.retailer.loyaltyClubDrivers[0] &&
                config.retailer.loyaltyClubDrivers[0].clientConfig &&
                config.retailer.loyaltyClubDrivers[0].clientConfig.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty);
            };

            self.login = function (username, password, recaptchaHash) {
                var defer = $q.defer();

                api.request({
                    method: 'POST',
                    url: '/retailers/:rid/sessions',
                    data: {
                        username: username,
                        password: password,
                        recaptchaHash: recaptchaHash
                    }
                }, {
                    withoutToast: self.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty()
                }).then(function (resp) {
                    userLoggedInSuccessfullySetSession(resp, defer, username);
                }, function (err) {
                    defer.reject(err);
                });

                return defer.promise;
            };

            self.generateOtpCode = function (otpCredentials, typeOfOtp, recaptchaHash) {
                var defer = $q.defer();

                api.request({
                    method: 'POST',
                    url: '/retailers/:rid/sessions/generate-otp-code',
                    data: {
                        otpCredentials: otpCredentials,
                        typeOfOtp: typeOfOtp,
                        recaptchaHash: recaptchaHash
                    }
                }, {
                    withoutToast: self.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty()
                }).then(function () {
                    defer.resolve({});
                }, function (err) {
                    defer.reject(err);
                });

                return defer.promise;
            };

            self.loginWithToken = function (userId, token, otpCredentials, otpType, recaptchaHash, email) {
                var defer = $q.defer();

                api.request({
                    method: 'POST',
                    url: '/retailers/:rid/sessions/token',
                    data: {
                        userId: userId,
                        email: email,
                        token: token,
                        otpCredentials: otpCredentials,
                        otpType: otpType,
                        recaptchaHash: recaptchaHash
                    }
                }, {
                    withoutToast: true
                }).then(function (resp) {
                    if (resp.message && resp.message.includes('foreignId not found')) {
                        defer.resolve(resp);
                    } else {
                        userLoggedInSuccessfullySetSession(resp, defer, userId);
                    }
                }, function (err) {
                    defer.reject(err);
                });

                return defer.promise;
            };

            self.loginWithFacebook = function () {
                var defer = $q.defer(),
                    windowReference = window.open('/retailers/' + config.retailer.id + '/sessions/facebook');

                function messageListener(e) {
                    if (!e.data.userId && !e.data.token) {
                        return;
                    }
                    window.removeEventListener('message', messageListener);
                    windowReference.close();

                    _setSession({
                        username: e.data.email,
                        userId: e.data.userId,
                        token: e.data.token,
                        organizationId: e.data.organizationId,
                        organizationTypeId: e.data.organizationTypeId,
                        organizationRoleId: e.data.organizationRoleId,
                        organizationBranches: e.data.organizationBranches
                    });
                    self.getData(true);

                    $rootScope.$emit('login');

                    defer.resolve(self.session);
                }

                window.addEventListener('message', messageListener);

                return defer.promise;
            };

            self.forgotPassword = function (email, recaptchaHash) {
                return api.request({
                    method: 'GET',
                    url: '/retailers/:rid/users/_forgottenPassword',
                    params: {
                        email: email,
                        recaptchaHash: recaptchaHash
                    }
                }, {
                    withoutToast: true
                });
            };

            self.forgotPasswordChange = function (email, resetCode, password) {
                return api.request({
                    method: 'POST',
                    url: '/retailers/:rid/users/_forgottenPassword',
                    data: {
                        email: email,
                        resetCode: resetCode,
                        password: password
                    }
                }).then(function () {
                    $location.search('email', null);
                    $location.search('resetPasswordCode', null);
                });
            };

            $rootScope.logout = false;

            self.setNewPassword = function (email, resetCode, password, user) {
                return api.request({
                    method: 'POST',
                    url: '/retailers/:rid/users/_setNewPassword',
                    data: Object.assign({}, user, {
                        email: email,
                        resetCode: resetCode,
                        password: password
                    })
                });
            };

            self.logout = function () {
                $rootScope.logout = true;

                api.request({
                    method: 'DELETE',
                    url: '/retailers/:rid/sessions/session',
                    headers :{
                        Authorization : self.getAuthorizationToken()
                    }
                }, {
                    fireAndForgot: true
                });
                _setSession({});
                angular.copy({}, self.data);
                _isFirstTime = true;

                $rootScope.$emit('logout');

                // @notice: identify that `we legged in as`,
                // following query params are required
                var qp = $location.search();
                if (qp.token && qp.userId) {
                    var params = {};
                    // keep it for local development
                    if (qp.domain) {
                        params.domain = qp.domain;
                    }
                    // remove query params, it will cause page reloading
                    $location.search(params);
                }
            };

            /**
             * @typedef {Object} GetUsersOptions
             * @property {boolean} isForceNewRequest // used when dont want to reuse getDataPromise
             */

            /**
             * @typedef {Object} GetUsersParams
             */

            /**
             * @param {boolean} isWithoutCache
             * @param {GetUsersParams} params
             * @param {GetUsersOptions} options 
             * @return {Promise<any>} userData
             */
            self.getData = function (isWithoutCache, params, options) {
                // Avoid parallel calls (use previous promise)
                if (getDataPromise && !(options && options.isForceNewRequest) ) { 
                    return getDataPromise;
                }

                if (!self.session.userId) {
                    return $q.reject(USER_ERRORS.NOT_LOGGED_IN);
                }

                return getDataPromise = new $q(function(resolve) {
                    if (config.branch) {
                        return resolve(config.branch.id);
                    }

                    $rootScope.$on('config.branch.change', function (event, branch) {
                        if (branch) {
                            resolve(branch.id);
                        }
                    });
                }).then(function(branchId) {
                    if (!self.session.userId) {
                        return $q.reject(USER_ERRORS.NOT_LOGGED_IN);
                    }

                    if (!isWithoutCache && Object.keys(self.data).length) {
                        return self.data;
                    }

                    return _getUserData(branchId, params);
                }).finally(function () {
                    getDataPromise = null;
                });
            };

            function userLoggedInSuccessfullySetSession(resp, defer, username) {
                _setSession({
                    username: username,
                    userId: resp.userId,
                    token: resp.token,
                    organizationId: resp.organizationId,
                    organizationTypeId: resp.organizationTypeId,
                    organizationBranches: resp.organizationBranches,
                    organizationRoleId: resp.organizationRoleId,
                    loyaltyClubCardId: resp.loyaltyClubCardId
                });

                if (!resp.isPolicyApproved && config.retailer.policyEnforcementModeId === POLICY_ENFORCEMENT_MODES.SHOW) {
                    _showApprovalDialog(resp.userId, false);
                }

                self.getData(true);

                $rootScope.$emit('login');

                defer.resolve(self.session);
            }

            /**
             * @param {number} branchId 
             * @param {GetUsersParams} extraParams 
             * @returns 
             */
            function _getUserData(branchId, extraParams) {
                var inviteId = Number(localStorageService.getItem('inviteId')),
                    promotionId = Number(localStorageService.getItem('promotionId'));

                //deleting only the columns to keep the same pointer to object
                angular.copy({ }, self.data);

                var params = {
                    isFirstTime: _isFirstTime,
                    branchId: branchId,
                    cartId: Number(localStorageService.getItem('serverCartId')) || null, //to avoid circular dependency exception
                };

                angular.extend(params, extraParams)

                if (inviteId && promotionId) {
                    params.invitePromotion = {
                        inviteId: inviteId,
                        promotionId: promotionId
                    };
                }
                params.refreshObligo = !!$rootScope.refreshObligo;

                return api.request({
                    method: 'GET',
                    url: '/retailers/:rid/users/:uid',
                    params: params
                }).then(function(resp) {
                    _isFirstTime = false;
                    $rootScope.refreshObligo = false;
                    if (inviteId || promotionId) {
                        localStorageService.removeItem('inviteId');
                        localStorageService.removeItem('promotionId');
                    }

                    if (!resp || !resp.length) {
                        throw new Error('User not found');
                    }

                    //fill only the columns to keep the same pointer to object
                    angular.copy(resp[0], self.data);

                    if (!self.session.username) {
                        self.session.username = self.data.email;
                    }

                    //== prevent displaying null null
                    self.data.firstName = !self.data.firstName ? '' : self.data.firstName;
                    self.data.lastName = !self.data.lastName ? '' : self.data.lastName;

                    $rootScope.isCreditCustomer = self.data.isCreditCustomer || false;
                    $rootScope.creditCustomerRemainingSum = $rootScope.isCreditCustomer && self.data.creditCustomerRemainingSum;

                    _setOrganizationSessionData();

					if (config.retailer.policyEnforcementModeId === POLICY_ENFORCEMENT_MODES.FORCE && !self.data.policyApprovalTime) {
						_showApprovalDialog(self.data.id, true);
					}
                    // Check for selected areas on login
                    var areaId = localStorageService.getItem('areaId');
                    var areaName = localStorageService.getItem('area');
                    if(!areaId && !areaName && self.data.areaId && self.data.areaName) {
                        localStorageService.setItem('areaId', +self.data.areaId);
                        localStorageService.setItem('area', self.data.areaName);
                        config.branchAreaId = self.data.areaId;
                        config.isUserDefaultArea = true;
                    }
                    _changeBranchIfNeeded();
                    return self.data;
                }).catch(function(err) {
					if (err.response && (err.response.code === "accountNotVerified" || err.response.code === "userVerificationExpired")) {
						self.logout();
					}
                });
            }

            //If selected branch is not the same branch that the customer placed last order, we should set the correct branch.
            //We are checking if the area id from the last order is related to selected branch. If not, we will put the branch
            //that the area id belongs to.
            function _changeBranchIfNeeded(){
                var _branchId,
                _localStorageBranchId = localStorageService.getItem('branchId');

                if(!_localStorageBranchId) {
                    angular.forEach(config.branch.areas, function(area)
                    {
                        if (area.id === parseInt(self.data.areaId)){
                            _branchId = config.branch.id;
                        }
                    });
                }

                if(!_branchId && !_localStorageBranchId){
                    angular.forEach(config.retailer.branches, function (branch) {
                        angular.forEach(branch.areas, function(area)
                        {
                            if (area.id === parseInt(self.data.areaId)){
                                _branchId = branch.id;
                            }
                        })
                    });
                    if(_branchId){
                        config.changeBranch(_branchId, self.data.areaId, {
                            newRetailerId: self.data.retailerId,
                            forceBranchChange: true
                        });
                    }
                }

            }

            /**
             * Set the user organization & loyalty card session data by the user data
             * @private
             */
            function _setOrganizationSessionData() {
                var session = {
                    username: self.session.username,
                    userId: self.session.userId,
                    token: self.session.token,
                };

                if (self.data.organization) {
                    session.organizationId = self.data.organization.id;
                    session.organizationTypeId = self.data.organization.organizationType;
                    session.organizationRoleId = self.data.organization.roleId;
                    session.organizationBranches = self.data.organization.branches;
                }

                var loyaltyClubCardId = self.data.loyaltyClubCardId || (self.data.loyaltyClubs && self.data.loyaltyClubs.length && self.data.loyaltyClubs[0].loyaltyCardId);
                if (loyaltyClubCardId) {
                    session.loyaltyClubCardId = loyaltyClubCardId;
                }

                _setSession(session);
            }

            self.register = function (userData) {
                return api.request({
                    method: 'POST',
                    url: '/retailers/:rid/users',
                    data: userData
                }).then(function (res) {
                    if (userData.email && userData.password)
                        return self.login(userData.email, userData.password);
                    return res;
                });
            };

            self.getUserTickets = function (getLastData) {
                return api.request({
                    method: 'GET',
                    url: '/retailers/:rid/users/tickets/customer',
                    params: {
                        getLastData: getLastData,
                    }
                }).then(function (data) {
                    return data.tickets;
                });
            };

            self.createUserTicketComment = function (comment) {
                return api.request({
                    method: 'POST',
                    url: '/retailers/:rid/users/tickets/customer',
                    data: comment
                }).then(function (data) {
                    return data;
                });
            };

            self.uploadTicketAttachment = uploadTicketAttachment;
            function uploadTicketAttachment(attachment) {
                if (!attachment) {
                    return $q.resolve();
                }

                var data = new FormData();
                data.append('attachment', attachment);

                return api.request({
                    method: 'POST',
                    url: '/retailers/:rid/tickets/comments/attachments/_upload',
                    data: data,
                    headers: {
                        'Content-Type': undefined
                    }
                });
            }

            self.verifyLoyaltyRegistration = function (cartId){
                return api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/users/:uid/loyalty',
                    data: { actionType: 1, cartId: cartId }
                }).then(function (result) {
                    if (result && result.error && result.error == 'noBenefitBarcode') {
                        dialog.show({
                            template: '<h4>{{error | translate}}.</h4>',
                            controller: ['$scope', function (scope) {
                                scope.error = 'Loyalty registration was not fully completed, registration voucher will be sent by mail';
                            }]
                        }).then(function (){
                            dialog.hide();
                            return self.getData(true);
                        });
                    }
                    return self.getData(true);
                });
            };

            function _setSession(session) {
                var newSession = {
                    username: session.username,
                    userId: session.userId,
                    token: session.token
                };

                if (session.organizationId && session.organizationTypeId !== ORGANIZATION_TYPES.SIMPLE) {
                    newSession.branchKeyOrganization = session.organizationId;
                    newSession.organizationRoleId = session.organizationRoleId;
                    newSession.organizationTypeId = session.organizationTypeId;
                    if (session.organizationBranches) {
                        newSession.organizationBranches = session.organizationBranches.map(function(branch) {
                            return branch.id;
                        });
                    }
                }

                if (session.loyaltyClubCardId) {
                    newSession.loyaltyClubCardId = session.loyaltyClubCardId;
                }

                var shouldReload = false;
                if (newSession.branchKeyOrganization !== self.session.branchKeyOrganization ||
                    newSession.organizationRoleId !== self.session.organizationRoleId ||
                    !_compareOrganizationBranches(newSession.organizationBranches, self.session.organizationBranches)) {
                    shouldReload = true;
                }

                angular.copy(newSession, self.session);
                self.save();
                if (shouldReload) {
                    Util = Util || $injector.get('Util');
                    // reload, since the organization data can affect permissions and show branches/data
                    $timeout(function() {
                        Util.reload(2000);
                    });
                }
            }

            function _compareOrganizationBranches(branchesA, branchesB) {
                if ((branchesA || []).length !== (branchesB || []).length) {
                    return false;
                }

                var branchesAMap = {};
                angular.forEach(branchesA || [], function(branchId) {
                    branchesAMap[branchId] = true;
                });
                for (var i = 0; i < (branchesB || []).length; i++) {
                    // when a branch id in B does not exist in A, it differs
                    if (!branchesAMap[branchesB[i]]) {
                        return false;
                    }
                }

                return true;
            }

            self.save = function () {
                localStorageService.setItem('session', self.session || '');
            };

            function isVerified() {
                return !config.retailer || config.retailer.settings.isUserVerificationActive !== 'true' ||
                    (self.data.verificationStatusId &&
                    self.data.verificationStatusId !== USER_VERIFICATION_STATUS.NOT_VERIFIED &&
                    self.data.verificationStatusId !== USER_VERIFICATION_STATUS.SENT);
            }

            function getPurchasesSortBoost() {
                if (!config.retailer.isUserPurchasesPersonalizationActive || !self.session.userId) {
                    return;
                }

                return {
                    sortType: SEARCH_SORT_BOOST_TYPES.USER_LAST_PURCHASED_PRODUCTS,
                    topPriority: JSON.stringify([{id: self.session.userId}])
                };
            }

            /**
             * Returns whether the given branch id is allowed by the user organization
             * @public
             *
             * @param {number} branchId
             *
             * @return {boolean}
             */
            function isOrganizationBranch(branchId) {
                var organizationBranches = self.session.organizationBranches;
                if (!organizationBranches) {
                    return true;
                }

                return !!organizationBranches.find(function(orgBranchId) {
                    return orgBranchId === branchId;
                });
            }

            /**
             * Resend activation link to user's email
             * @public
             *
             * @param {String} email
             *
             * @return {boolean}
             */
            function resendActivationLink(email) {
                return api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/users/verification/resend-activation-link',
                    data: { email: email }
                });
            }

            /**
             * @typedef {Object} AreaInput
             * @property {number} deliveryTypeId
             * @property {number} id
             * @property {number=} name
             * @property {number=} branchId
             * @property {{ id: number }} branch
             */

            /**
             * @param {AreaInput} area
             * @param {number[]} deliveryTypeIds
             * @returns {Promise<null | any>}
             */
            function updateDeliveryLineAnonymous(area, deliveryTypeIds){
                var cartId = Number(localStorageService.getItem('serverCartId'));
                
                // return null so flow can process fallback case if data not found
                if(!area || !area.id || !deliveryTypeIds || !deliveryTypeIds.length || !cartId){
                    return Promise.resolve(null);
                }

                var body = {
                    areaId: area.id,
                    deliveryTypeIds: deliveryTypeIds
                }

                return api.request({
                  method: "POST",
                  url: "/v2/retailers/:rid/branches/:bid/carts/" + cartId + "/delivery-line",
                  data: body,
                });
            }

            /**
             * Set user area
             * @public
             * @param {AreaInput} area
             * @param {number=} userId
             * @return {error: boolean}
             */
            function setUserArea(area, userId) {
                var deliveryTypeIds = [area.deliveryTypeId];
                var includeDeliveryFeeInCart = $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true'

                // ECOM-2695: Must send both area delivery type and "retailer delivery type" (I guess) to GET LIST AREAS FROM CACHE in server => send DELIVERY (1), EXPRESS_DELIVERY (5)
                if (area.deliveryTypeId === BRANCH_DELIVERY_TYPES.DELIVERY) {
                    deliveryTypeIds.push(BRANCH_DELIVERY_TYPES.EXPRESS_DELIVERY);
                }

                if(!userId){
                    return updateDeliveryLineAnonymous(area, deliveryTypeIds);
                }

                return api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/users/:uid/set-area',
                    data: {
                        userId: userId,
                        areaId: area.id,
                        areaName: area.name,
                        branchId: area.branchId || (area.branch && area.branch.id) || null,
                        deliveryTypeId: deliveryTypeIds,
                        cartId: Number(localStorageService.getItem('serverCartId')) || null,
                        includeDeliveryFeeInCart:  includeDeliveryFeeInCart
                    }
                });
            }

            /**
             * Show approval dialog
             * @private
             *
             * @param {Number} userId
             * @param {Boolean} isForce
             *
             * @return {boolean}
             */
            function _showApprovalDialog(userId, isForce) {
                dialog.show({
                    templateUrl: 'template/dialogs/policy-approval/index.html',
                    disableClosing: isForce,
                    controller: ['$scope', function($scope) {
                        var policyApprovalCtrl = this;
                        policyApprovalCtrl.submit = submit;
                        policyApprovalCtrl.showError = false;

                        function submit(form, event) {
                            if (!form.policyApproval.$viewValue) {
                                policyApprovalCtrl.showError = true;
                                return;
                            }
                            return api.request({
                                method: 'PATCH',
                                url: '/retailers/:rid/users/' + userId,
                                data: {policyApproval: true}
                            }).then(function() {
                                dialog.hide();
                            });

                        }
                    }],
                    controllerAs: 'policyApprovalCtrl',
                    styleClass: 'policy-approval',
                    ariaLabelledby: 'policy_approval_dialog_title'
                });
            }

            function validateResetCode(data) {
				return api.request({
					method: 'POST',
					url: '/retailers/:rid/resetCodeValidation',
					data: {
                        email: data.email,
                        resetPasswordCode: Number(data.resetPasswordCode)
					}
				})
            }

            function updateReplacementSuggestions(selections) {
                var branchId = localStorageService.getItem('branchId'),
                    userId = self.session.userId;

                if (!branchId || !userId || !selections || !selections.length) {
                    return;
                }

                return api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/users/:uid/replacement-suggestions',
                    params: {
                        branchId: branchId,
                        userId: userId
                    },
                    data: {
                        selections: selections
                    }
                }, {
                    fireAndForgot: true
                });
            }

            function getTypeOfOTPFromCredentials(otpCredentials) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(otpCredentials) ? $rootScope.LOGIN_WITH_OTP_OPTIONS.EMAIL : $rootScope.LOGIN_WITH_OTP_OPTIONS.PHONE_NUMBER;
            }

            function getReplacementSuggestions(productIds, excludeIds) {
                var branchId = localStorageService.getItem('branchId'),
                    userId = self.session.userId;

                if (!branchId || !userId || !productIds || !productIds.length) {
                    return $q.resolve([]);
                }

                return api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/users/:uid/replacement-suggestions/getReplacements',
                    params: {
                        branchId: branchId
                    },
                    data: {
                        productIds: productIds,
                        excludeIds: excludeIds
                    }
                }, {
                    fireAndForgot: true
                });
            }
        }]);
})(angular, app);
