(function (angular, app) {
  "use strict";

  app.directive("spPointRedemption", [
    "LocalStorage",
    "Util",
    function ($rootScope, $timeout, LocalStorageService, Util) {
      return {
        restrict: "E",
        replace: true,
        scope: {
        },
        templateUrl: "template/directives/sp-point-redemption/index.html",
        controller: [ "$scope", "$rootScope", "$state", "User", "$q", "Api", "Util", "Config", 'Loyalty', "$timeout", "$element", "$attrs", "ORGANIZATION_TYPES", "USER_LOYALTY_TYPES",
          function ( $scope, $rootScope, $state, user, $q, api, Util, Config, Loyalty, $timeout, $element, $attrs, ORGANIZATION_TYPES, USER_LOYALTY_TYPES) {
            var _listeners = [];
            var spPointRedemptionCtr = this;
            $scope.language = Config.language.culture;
            $scope.numberOfCoupons = null;
            $scope.totalSpecials = null;
            $scope.user = user;
            $scope.userPoints = null;
            $scope.memberLoyaltyType = {};
            $scope.memberLoyaltyTypeConfig = {};
            $scope.isMemberLoyaltyRegistered = false;
            $scope.loyaltyClubDriver = null;
            $scope.loyaltyClubDriverClientConfig = null;
            $scope.onLoyaltyComponentClick = _onLoyaltyComponentClick;
            $scope.onSpecialsClick = _onSpecialsClick;
            $scope.onClickNonLoyaltyBannerButton = onClickNonLoyaltyBannerButton

            $scope.$watch(
              function () {
                return Config.language.culture;
              },
              function (newCulture, oldCulture) {
                $scope.language = newCulture;
              }
            );

            _init()

            function _init(){
              _getUserpoints().then(function () {
                _getmemberLoyaltyConfig();
              });
              _setMembershipClub();
              setNumberOfCoupons();
              setNumberOfSpecials();
            }

            _listeners.push($rootScope.$on("numberOfCouponsHomepage",
              function (event, numberOfCoupons) {
                $scope.numberOfCoupons = numberOfCoupons;
              }
            ));

            _listeners.push($rootScope.$on("login", function () {
              setNumberOfCoupons();
              setNumberOfSpecials();
              _getUserpoints().then(function(){
                 _getmemberLoyaltyConfig();
              });
            }));

            function setNumberOfCoupons() {
              if ($scope.user.session.userId && $rootScope.config.retailer.settings.enablePersonalizedCoupons) {
                Loyalty.getCoupons(true, true).then(function (couponsData) {
                    var coupons = couponsData.coupons || couponsData;
                    $scope.numberOfCoupons = coupons.length;
                  });
              } else {
                delete $scope.numberOfCoupons;
              }
            }

            function setNumberOfSpecials() {
              if ($scope.user.session.userId && $rootScope.config.retailer.settings.enablePersonalizedCoupons ) {
                Loyalty.getSpecials(0, 1).then(function (specialsData) {
                    $scope.totalSpecials = specialsData.total || 0;
                  });
              } else {
                delete $scope.totalSpecials;
              }
            }

            function _isOrganizationObligoType() {
              return (
                $scope.user.data &&
                $scope.user.data.organization &&
                $scope.user.data.organization.organizationType === ORGANIZATION_TYPES.OBLIGO
              );
            }

            function _setMembershipClub(state) {
              $scope.isAvailableMembershipClub = Config.retailer.loyaltyClub && !_isOrganizationObligoType();
              var loyaltyClubDriver = !!Config.retailer.loyaltyClubDrivers && Config.retailer.loyaltyClubDrivers.length === 1 && Config.retailer.loyaltyClubDrivers[0],
                isPremiumLoyaltyClub = Util.isLoyaltyPremiumPackageEnabled() && !!loyaltyClubDriver;
              if (state) {
                $scope.membershipClubState = {
                  state: isPremiumLoyaltyClub && !!loyaltyClubDriver && loyaltyClubDriver.clientConfig.extendedLoyaltyClub ? state : "loyalty",
                  stateParams: isPremiumLoyaltyClub ? {loyaltyClubDriverId: loyaltyClubDriver.loyaltyClubDriverId} : null,
                }
              } else {
                $scope.membershipClubState = {
                  state: isPremiumLoyaltyClub ? "premiumLoyaltyClub" : !!loyaltyClubDriver && loyaltyClubDriver.clientConfig.extendedLoyaltyClub ? "extendedLoyalty" : "loyalty",
                  stateParams: isPremiumLoyaltyClub ? {loyaltyClubDriverId: loyaltyClubDriver.loyaltyClubDriverId} : null,
                }
              }
            }

            function _getmemberLoyaltyConfig() {
              $scope.loyaltyClubDriver = Loyalty.getRetailerClubDriver();
              if ($scope.loyaltyClubDriver) {
                $scope.loyaltyClubDriverClientConfig = Loyalty.getRetailerDefaultClub();
                $scope.memberLoyaltyType[USER_LOYALTY_TYPES.NOT_REGISTER] = !$scope.user.session.userId;

                if ( $scope.loyaltyClubDriverClientConfig ) {
                  $scope.loyaltyClubConfigHomepage = $scope.loyaltyClubDriverClientConfig.loyaltyPages.homepage;
                  $scope.memberLoyaltyTypeConfig[USER_LOYALTY_TYPES.NOT_REGISTER] = $scope.loyaltyClubConfigHomepage.userType[USER_LOYALTY_TYPES.NOT_REGISTER];  
                }

                /* If logined, reset loyalty club config based on user's registered loyalty*/
                $scope.user.getData().then(function (data) {
                  if (!data) return;
                  $scope.memberLoyaltyType[USER_LOYALTY_TYPES.NOT_REGISTER] = !data.loyaltyClubs.length;
                  if (data.loyaltyClubs.length > 0 && $scope.loyaltyClubDriver) {
                    $scope.userLoyaltyClub = Loyalty.getUserLoyaltyClub(data);
                    $scope.isMemberLoyaltyRegistered = !!$scope.userLoyaltyClub;
                    $scope.loyaltyClubDriverClientConfig = Loyalty.getRetailerClubConfig($scope.userLoyaltyClub.loyaltyClubId);
                    $scope.loyaltyClubConfigHomepage = $scope.loyaltyClubDriverClientConfig.loyaltyPages.homepage;
                    $scope.isEligibleToRedeem = $scope.userPoints >= $scope.loyaltyClubDriverClientConfig.minPointsNum;
                    $scope.cashbackAmount = Math.floor($scope.userPoints * $scope.loyaltyClubDriverClientConfig.pointsToCashbackRatio);
                    $scope.percentOfProgress = ($scope.userPoints / $scope.loyaltyClubDriverClientConfig.minPointsNum) * 100;
                    $scope.memberLoyaltyTypeConfig[USER_LOYALTY_TYPES.ELIGIBLE_TO_REDEEM] = $scope.loyaltyClubConfigHomepage.userType[USER_LOYALTY_TYPES.ELIGIBLE_TO_REDEEM];
                    $scope.memberLoyaltyTypeConfig[USER_LOYALTY_TYPES.NOT_ELIGIBLE_TO_REDEEM] = $scope.loyaltyClubConfigHomepage.userType[USER_LOYALTY_TYPES.NOT_ELIGIBLE_TO_REDEEM];
                    $scope.memberLoyaltyTypeConfig[USER_LOYALTY_TYPES.POINT_IS_ZERO] = $scope.loyaltyClubConfigHomepage.userType[USER_LOYALTY_TYPES.POINT_IS_ZERO];
                    /*
                      1: USER_LOYALTY_TYPES.ELIGIBLE_TO_REDEEM
                      2: USER_LOYALTY_TYPES.NOT_ELIGIBLE_TO_REDEEM
                      3: USER_LOYALTY_TYPES.NOT_REGISTER
                      4: USER_LOYALTY_TYPES.POINT_IS_ZERO
                    */
                    $scope.memberLoyaltyType = {
                      1: $scope.isMemberLoyaltyRegistered && $scope.isEligibleToRedeem,
                      2: $scope.isMemberLoyaltyRegistered && !!$scope.userPoints && !$scope.isEligibleToRedeem,
                      3: !$scope.isMemberLoyaltyRegistered,
                      4: $scope.isMemberLoyaltyRegistered && !$scope.userPoints,
                    };
                  }
                });
              }
            }

            function _getUserpoints() {
              if(!$scope.user.session.userId){
                return Promise.resolve(null);
              }
              var loyaltyClubDriver = Loyalty.getRetailerClubDriver();
              if (!loyaltyClubDriver) {
                return Promise.resolve(null);
              }
              var p = $scope.user.getData().then(function(data) {
                if (!data) return;
                $scope.userLoyaltyClub = Loyalty.getUserLoyaltyClub(data);
                if (!$scope.userLoyaltyClub) return;
                $scope.activeCashbackLoyalty = Loyalty.getRetailerClubConfig($scope.userLoyaltyClub.loyaltyClubId);
                if ($scope.activeCashbackLoyalty.pointsToCashback) {
                  $scope.userCashbackLoyalty = data.loyaltyClubs.length && data.loyaltyClubs.find(function (club) {
                    return club.loyaltyClubDriverId === loyaltyClubDriver.loyaltyClubDriverId;
                  });
                }
                if($scope.userCashbackLoyalty) {
                  return Util.getUserCashbackPoints($scope.userCashbackLoyalty.id).then(function (response) {
                      $scope.userPoints = response.points || 0;
                  })
                }
              }).catch(function(){
                return;
              })
              return p;
            }

            function _resetmemberLoyaltyConfig() {
              $scope.isMemberLoyaltyRegistered = false;
              /*
                1: USER_LOYALTY_TYPES.ELIGIBLE_TO_REDEEM
                2: USER_LOYALTY_TYPES.NOT_ELIGIBLE_TO_REDEEM
                3: USER_LOYALTY_TYPES.NOT_REGISTER
                4: USER_LOYALTY_TYPES.POINT_IS_ZERO

              */
              $scope.memberLoyaltyType = {
                1: false,
                2: false,
                3: !$scope.user.session.userId,
                4: false
              };
                
              $scope.loyaltyClubDriverClientConfig = Loyalty.getRetailerDefaultClub();
              if ( $scope.loyaltyClubDriverClientConfig.loyaltyPages ) {
                $scope.loyaltyClubConfigHomepage = $scope.loyaltyClubDriverClientConfig.loyaltyPages.homepage;
                /*
                  1: USER_LOYALTY_TYPES.ELIGIBLE_TO_REDEEM
                  2: USER_LOYALTY_TYPES.NOT_ELIGIBLE_TO_REDEEM
                  3: USER_LOYALTY_TYPES.NOT_REGISTER
                  4: USER_LOYALTY_TYPES.POINT_IS_ZERO
                */
                $scope.memberLoyaltyTypeConfig = {
                  1: null,
                  2: null,
                  3: $scope.loyaltyClubConfigHomepage.userType[USER_LOYALTY_TYPES.NOT_REGISTER],
                  4: null
                };
              }
            }

            function getExternalLinkFromLoyaltyHomeConfig(loyaltyHomepageConfig){
              var nonLoyaltyUserType = USER_LOYALTY_TYPES.NOT_REGISTER

              if(!loyaltyHomepageConfig || !nonLoyaltyUserType){
                  return null
              }

              var externalRegistration = loyaltyHomepageConfig.userType[nonLoyaltyUserType].externalRegistration

              if(!externalRegistration.isActive){
                  return null
              }
              
              return externalRegistration.link
          }

            function onClickNonLoyaltyBannerButton(event){
              event.stopPropagation()

              if(!user.session.userId){
                return _onSignUpClick(event)
              }

              var externalLink = getExternalLinkFromLoyaltyHomeConfig($scope.loyaltyClubConfigHomepage)

              if(externalLink){
                return Util.goToHref(externalLink)
              }

              _onLoyaltyComponentClick(event, "extendedLoyalty")
            }

            function _onLoyaltyComponentClick(event, state) {
              event.stopPropagation();
              if (state) _setMembershipClub(state);
              else _setMembershipClub();
              var url = 'app.userEdit.' + $scope.membershipClubState.state,
                params = $scope.membershipClubState.stateParams ? $scope.membershipClubState.stateParams : '';
              if (params) $state.go(url, params);
              else $state.go(url);
            }
          
            function _onSignUpClick(event) {
              event.stopPropagation();
              Util.openLoginDialog();
            }
            
            function _onSpecialsClick() {
              if (!$scope.loyaltyClubDriver.id) $state.go('app.specials');
              $state.go('app.specials', {"filters": {"type": [$scope.loyaltyClubDriver.id]}});
            }

            _listeners.push($rootScope.$on("logout", _resetmemberLoyaltyConfig));

            Util.destroyListeners($scope, _listeners);
          },
        ],
      };
    },
  ]);
})(angular, app);
